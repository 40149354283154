<template>
  <div class="q-pa-md q-gutter-sm">
    <q-dialog v-model="dialog" transition-show="fade" transition-hide="fade" :backdrop-filter="backdropFilter">
      <q-card style="width: 700px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Questionnaire</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>

        <q-card-section class="q-pt-none">
          <transition appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
          <div>
            <div class="row items-start no-wrap q-mt-sm" v-for="n in 7" :key="n" v-show="visible">
              <div class="col q-pl-sm">
                {{ n }}.
                <q-skeleton type="text" square height="30px" animation="fade" />
                <q-skeleton type="text" square height="15px" width="80%" animation="fade"/>
                <q-skeleton type="text" square height="15px" width="75%" animation="fade"/>
                <q-skeleton type="text" square height="15px" width="65%" animation="fade"/>
                <q-skeleton type="text" square height="15px" width="75%" animation="fade"/>
              </div>
            </div>
             <q-list separator v-for="item,index in examResult" :key="item" v-show="!visible">
              <q-item-label class="text-weight-bold">{{ index + 1 }}. {{ item.question }}</q-item-label>

              <q-item clickable v-ripple v-for="choice,index2 in item.choices" :key="choice">
                <q-item-section >
                  <q-item-label>{{ choicesAlpha[index2] }}. {{ choice.choice }}</q-item-label>
                  <q-item-label caption lines="1" v-show="item.answer != choice.id && choice.answer == 1" class="text-green">Correct Answer</q-item-label>
                </q-item-section>

                <q-item-section side top v-show="item.answer == choice.id && choice.answer == 1" >
                  <q-icon name="check_circle" color="green" />
                </q-item-section>

                <q-item-section side top v-show="item.answer == choice.id && choice.answer == 0" >
                  <q-icon name="cancel" color="red" />
                </q-item-section>

              </q-item>
            </q-list> 
          </div>
        </transition>
        </q-card-section>
        <!-- <q-inner-loading :showing="visible" label="Please wait..." label-class="text-teal" label-style="font-size: 1.1em"/> -->
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import { ref } from 'vue'
import EventBus from "@/EventBus"
import axios from 'axios'

export default {

  setup () {
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const dialog = ref(false)
    const visible = ref(false)
    const fetching = ref(false)
    const choicesAlpha = ref(['A','B','C','D','E','F','G','H','I','J','K','L'])
    const examResult = ref([])
    const backdropFilter = ref('grayscale(100%)')

    EventBus.on("openDialog",(val) => {
        dialog.value = val.status

        viewExam (val.obj.id)
    })

    function viewExam (id) {
        visible.value = true
        axios.get(process.env.VUE_APP_API_URL + '/exam/'+id,{
          headers: { 'Authorization': `Bearer ${token.value}`},
        }).then((res) => {
          examResult.value = res.data
          visible.value = false
        })
    }

    return {
      choicesAlpha,
      dialog,
      visible,
      fetching,
      token,
      examResult,
      backdropFilter,
    }
  }
}
</script>