<template>
  <div class="q-pa-md q-gutter-sm">
    <q-dialog
      v-model="confirm"
      persistent
      :maximized="maximizedToggle"
      transition-show="slide-up"
      transition-hide="slide-down"
    >
      <q-card>
        <q-bar>
          <q-space />

          <q-btn dense flat icon="minimize" @click="maximizedToggle = false" :disable="!maximizedToggle">
            <q-tooltip v-if="maximizedToggle" class="bg-white text-primary">Minimize</q-tooltip>
          </q-btn>
          <q-btn dense flat icon="crop_square" @click="maximizedToggle = true" :disable="maximizedToggle">
            <q-tooltip v-if="!maximizedToggle" class="bg-white text-primary">Maximize</q-tooltip>
          </q-btn>
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">Close</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section>
          <div class="text-h6">{{ object.firstname }} {{ object.lastname }}</div>
        </q-card-section>

        <q-card-section>
          <q-avatar size="100px"><img src="https://cdn-icons-png.flaticon.com/512/1144/1144760.png"></q-avatar>
           <q-chip outline color="teal" text-color="white" icon="email">
            {{ object.email }}
          </q-chip> 
          <q-chip outline color="teal" text-color="white" icon="phone_iphone">
            {{ object.mobile }}
          </q-chip> 
        </q-card-section>

        <q-card-section class="q-pt-none">
           <div v-show="exams.length == 0 && !isLoading" class="q-pa-md row items-start q-gutter-md">
            <p class="text-body1 absolute-center">
               <q-chip outline color="red" text-color="white" icon="bookmark">No Exam Available</q-chip>
            </p>
          </div> 
           <q-list v-show="exams.length > 0">
              <q-item class="q-my-sm" v-for="exam in exams" :key="exam.id">
                <q-item-section avatar>
                  <q-avatar color="teal" text-color="white">{{ exam.score }}</q-avatar>
                </q-item-section>


                <q-item-section>
                  <q-item-label>{{ exam.module }}</q-item-label>
                  <q-item-label caption lines="1">{{ exam.time_consume }}</q-item-label>
                  <q-item-label caption lines="1">{{ exam.date_created }}</q-item-label>
                  <q-item-label caption lines="1">{{ exam.average }}%</q-item-label>
                </q-item-section>

                <q-item-section side>
                  <div class="text-grey-8 q-gutter-xs">
                    <q-btn size="sm" round outline color="blue" @click="openModal(exam)">
                      <i class="fas fa-list"></i>
                      <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                        <strong>Questionnaire</strong>
                      </q-tooltip>
                    </q-btn>
                    <q-btn size="sm" round outline color="green" @click="downloadExam(exam.id)">
                      <i class="fas fa-file-excel"></i>
                      <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                        <strong>Download Excel</strong>
                      </q-tooltip>
                    </q-btn>
                    <q-btn size="sm" round outline color="red" @click="downloadPdfExam(exam.id)">
                      <i class="fas fa-file-pdf"></i>
                      <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]" >
                        <strong>Download Pdf</strong>
                      </q-tooltip>
                    </q-btn>
                  </div>
                </q-item-section>
              </q-item>
            </q-list>
        </q-card-section>
      </q-card>
    </q-dialog>
    <ViewExam/>
  </div>
</template>
<script>
import { ref } from 'vue'
import EventBus from "@/EventBus"
import { useQuasar, QSpinnerPie } from 'quasar'
import axios from 'axios'
import ViewExam from "./../exams/ViewExam.vue";
export default {
  components: {
    ViewExam
  },

  setup () {
    const $q = useQuasar()
    const token = ref(window.$cookies.get(process.env.VUE_APP_API_KEY).token)
    const confirm = ref(false)
    const maximizedToggle = ref(true)
    const id = ref(null)
    const object = ref({})
    const exams = ref([])
    const isLoading = ref(false)

    EventBus.on("viewProfile",(obj) => {
        confirm.value = obj.status
        id.value = obj.obj.id
        object.value = obj.obj
        getExamList(obj.obj.id)
    })

    const openModal = (obj) => {
      EventBus.emit("openDialog",{status:true,obj:obj})
    }

    function getExamList (id)  {
        isLoading.value = true
        $q.loading.show({
          spinner: QSpinnerPie,
          spinnerColor: 'red',
          messageColor: 'black',
          backgroundColor: 'grey',
          message: 'Fetching Records.<br><span class="text-italic">Please wait...</span>',
          html: true
        })
        axios.get(process.env.VUE_APP_API_URL + '/exam/individual',{
          headers: { 'Authorization': `Bearer ${token.value}`},
          params: {
              user_id: id,
           }
        }).then((res) => {
          isLoading.value = false
          $q.loading.hide()
          exams.value = res.data
        }).catch(error => {
          console.log(error)
        });
    }

    const downloadExam = (id) => {
       window.open(process.env.VUE_APP_API_URL + '/download/exam/'+id, '_blank');
    }

    const downloadPdfExam = (id) => {
       window.open(process.env.VUE_APP_API_URL + '/download/pdf/exam/'+id, '_blank');
    }

    return {
      confirm,
      id,
      maximizedToggle,
      object,
      isLoading,
      exams,
      openModal,
      downloadExam,
      downloadPdfExam
    }
  }
}
</script>